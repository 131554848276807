import store from "@/store";
import {
  phasesNames,
  phaseValidationStatus,
  clientPhasesNames,
} from "@/constants/dossier.structure";
import useAlert from "@/hooks/alert";
import { commonGlobalRoutes } from "@/utils/global.router";
import { dossierPricingPhasesAreValid } from "@/utils/dossier";
import useClientPhases from "@/hooks/useClientPhases";

function requiresAuthButNotAuth(to) {
  return to.meta.requiresAuth && !store.getters["auth/isAuthenticated"];
}
function requiresUnauthButAuth(to) {
  return to.meta.requiresUnauth && store.getters["auth/isAuthenticated"];
}

const toDefaultRequiresUnauth = { name: "route.login", replace: true };
const toDefaultRequiresAuth = { name: "route.contacts", replace: true };
function toLastRoute(lastRoute) {
  return { name: lastRoute.name, replace: true };
}

function accessDenied() {
  const { alertError } = useAlert();
  alertError({ text: "Accès refusé" });
}

async function restrictDossierPhaseAccess(to) {
  const isRoutePhase1to8 =
    to.name === "route.contacts.dossierDetails.1" ||
    to.name === "route.contacts.dossierDetails.2" ||
    to.name === "route.contacts.dossierDetails.2-1" ||
    to.name === "route.contacts.dossierDetails.2-2" ||
    to.name === "route.contacts.dossierDetails.3" ||
    to.name === "route.contacts.dossierDetails.3-1" ||
    to.name === "route.contacts.dossierDetails.3-2" ||
    to.name === "route.contacts.dossierDetails.3-3" ||
    to.name === "route.contacts.dossierDetails.4" ||
    to.name === "route.contacts.dossierDetails.5" ||
    to.name === "route.contacts.dossierDetails.6" ||
    to.name === "route.contacts.dossierDetails.7" ||
    to.name === "route.contacts.dossierDetails.7-1" ||
    to.name === "route.contacts.dossierDetails.7-2" ||
    to.name === "route.contacts.dossierDetails.7-3" ||
    to.name === "route.contacts.dossierDetails.7-4" ||
    to.name === "route.contacts.dossierDetails.8";

  if (!isRoutePhase1to8) return false;

  let dossier = store.getters["entities/dossiers/get"](to.params.id);
  if (!dossier) {
    await store.dispatch("entities/dossiers/getDossier", { id: to.params.id });
    dossier = store.getters["entities/dossiers/get"](to.params.id);
  }

  return (
    dossier.phasesStatuses[phasesNames.PHASE_A.key].status === phaseValidationStatus.INVALID.key ||
    dossier.phasesStatuses[phasesNames.PHASE_A.key].status === phaseValidationStatus.FUTURE.key ||
    dossier.phasesStatuses[phasesNames.PHASE_B.key].status === phaseValidationStatus.INVALID.key ||
    dossier.phasesStatuses[phasesNames.PHASE_B.key].status === phaseValidationStatus.FUTURE.key
  );
}

async function restrictPartnerDossierPricingPhaseAccess(to) {
  const isRoutePhaseAtoB =
    to.name === "route.contacts.dossierDetails.a" || to.name === "route.contacts.dossierDetails.b";

  if (!isRoutePhaseAtoB) return false;

  let dossier = store.getters["entities/dossiers/get"](to.params.id);
  if (!dossier) {
    await store.dispatch("entities/dossiers/getDossier", { id: to.params.id });
    dossier = store.getters["entities/dossiers/get"](to.params.id);
  }

  return dossierPricingPhasesAreValid(dossier.phasesStatuses);
}

async function restrictClientPhaseAccess(to) {
  const isRoutePhase1 = to.name === "route.contacts.dossierDetails.1";
  const isRoutePhase2 = to.name === "route.contacts.dossierDetails.2";
  const isRoutePhase3 = to.name === "route.contacts.dossierDetails.3";
  const isRoutePhase4 = to.name === "route.contacts.dossierDetails.4";
  const isRoutePhase5 = to.name === "route.contacts.dossierDetails.5";

  if (!isRoutePhase1 && !isRoutePhase2 && !isRoutePhase3 && !isRoutePhase4 && !isRoutePhase5)
    return false;

  let dossier = store.getters["entities/dossiers/get"](to.params.id);
  if (!dossier) {
    await store.dispatch("entities/dossiers/getDossier", { id: to.params.id });
    dossier = store.getters["entities/dossiers/get"](to.params.id);
  }

  const { firstNotValidPhase } = useClientPhases(dossier);
  const activePhaseUrlKey = firstNotValidPhase.value;

  if (
    (activePhaseUrlKey === clientPhasesNames.PHASE_1.urlKey ||
      activePhaseUrlKey === clientPhasesNames.PHASE_2.urlKey) &&
    (isRoutePhase3 || isRoutePhase4 || isRoutePhase5)
  )
    return true;

  if (activePhaseUrlKey === clientPhasesNames.PHASE_3.urlKey && (isRoutePhase4 || isRoutePhase5))
    return true;

  return false;
}

const commonRoutes = ({
  abPhasesIncluded = false,
  dossiersPhaseIncluded = true,
  trashedDocumentsIncluded = false,
} = {}) => {
  return [
    ...commonGlobalRoutes(),
    {
      path: "/login",
      name: "route.login",
      component: () => {
        return import(/* webpackChunkName: "login" */ "@/vue/views/common/auth/LoginView.vue");
      },
      meta: { requiresUnauth: true },
    },
    {
      path: "/activer-compte",
      name: "route.activateAccount",
      component: () => {
        return import(
          /* webpackChunkName: "activate-account" */ "@/vue/views/common/auth/ActivateAccountView.vue"
        );
      },
      meta: { requiresUnauth: true },
    },
    {
      path: "/mot-de-passe-oublie",
      name: "route.forgotPassword",
      component: () => {
        return import(
          /* webpackChunkName: "forgot-password" */ "@/vue/views/common/auth/ForgotPasswordView.vue"
        );
      },
      meta: { requiresUnauth: true },
    },
    ...(dossiersPhaseIncluded
      ? [
          {
            path: "/",
            name: "route.contacts",
            redirect: { name: "route.contacts.dossiersList" },
            component: () =>
              import(
                /* webpackChunkName: "contacts" */ "@/vue/views/common/dossiers/DossiersView.vue"
              ),
            meta: { requiresAuth: true },
            children: [
              {
                path: "",
                name: "route.contacts.dossiersList",
                component: () =>
                  import(
                    /* webpackChunkName: "contacts" */ "@/vue/views/common/dossiers/DossiersListView.vue"
                  ),
              },
              {
                path: "dossier/:id",
                name: "route.contacts.dossierDetails",
                component: () =>
                  import(
                    /* webpackChunkName: "contact-details" */ "@/vue/views/common/dossiers/DossierDetailsView.vue"
                  ),
                children: [
                  ...(abPhasesIncluded
                    ? [
                        {
                          path: "a",
                          name: "route.contacts.dossierDetails.a",
                          component: () =>
                            import(
                              /* webpackChunkName: "contact-details" */ "@/vue/views/common/dossiers/phases/PhaseAView.vue"
                            ),
                        },
                        {
                          path: "b",
                          name: "route.contacts.dossierDetails.b",
                          component: () =>
                            import(
                              /* webpackChunkName: "contact-details" */ "@/vue/views/common/dossiers/phases/PhaseBView.vue"
                            ),
                        },
                      ]
                    : []),
                  ...(trashedDocumentsIncluded
                    ? [
                        {
                          path: "documents-supprimes",
                          name: "route.contacts.dossierDetails.trashedDocs",
                          component: () =>
                            import(
                              /* webpackChunkName: "contact-details" */ "@/vue/views/admin/TrashedDocsView.vue"
                            ),
                        },
                      ]
                    : []),
                  {
                    path: "notifications",
                    name: "route.contacts.dossierDetails.notifications",
                    component: () => {
                      return import(
                        /* webpackChunkName: "notifications" */ "@/vue/views/common/dossiers/DossierDetailsNotificationsView.vue"
                      );
                    },
                    meta: { requiresAuth: true },
                  },
                  {
                    path: "1",
                    name: "route.contacts.dossierDetails.1",
                    component: () =>
                      import(
                        /* webpackChunkName: "contact-details" */ "@/vue/views/common/dossiers/phases/Phase1View.vue"
                      ),
                  },
                  {
                    path: "2",
                    name: "route.contacts.dossierDetails.2",
                    component: () =>
                      import(
                        /* webpackChunkName: "contact-details" */ "@/vue/views/common/dossiers/phases/Phase2View.vue"
                      ),
                    redirect: { name: "route.contacts.dossierDetails.2-1" },
                    children: [
                      {
                        path: "2-1",
                        name: "route.contacts.dossierDetails.2-1",
                        component: () =>
                          import(
                            /* webpackChunkName: "contact-details" */ "@/vue/views/common/dossiers/phases/Phase2x1View.vue"
                          ),
                      },
                      {
                        path: "2-2",
                        name: "route.contacts.dossierDetails.2-2",
                        component: () =>
                          import(
                            /* webpackChunkName: "contact-details" */ "@/vue/views/common/dossiers/phases/Phase2x2View.vue"
                          ),
                      },
                    ],
                  },
                  {
                    path: "3",
                    name: "route.contacts.dossierDetails.3",
                    component: () =>
                      import(
                        /* webpackChunkName: "contact-details" */ "@/vue/views/common/dossiers/phases/Phase3View.vue"
                      ),
                    redirect: { name: "route.contacts.dossierDetails.3-1" },
                    children: [
                      {
                        path: "3-1",
                        name: "route.contacts.dossierDetails.3-1",
                        component: () =>
                          import(
                            /* webpackChunkName: "contact-details" */ "@/vue/views/common/dossiers/phases/Phase3x1View.vue"
                          ),
                      },
                      {
                        path: "3-2",
                        name: "route.contacts.dossierDetails.3-2",
                        component: () =>
                          import(
                            /* webpackChunkName: "contact-details" */ "@/vue/views/common/dossiers/phases/Phase3x2View.vue"
                          ),
                      },
                      {
                        path: "3-3",
                        name: "route.contacts.dossierDetails.3-3",
                        component: () =>
                          import(
                            /* webpackChunkName: "contact-details" */ "@/vue/views/common/dossiers/phases/Phase3x3View.vue"
                          ),
                      },
                    ],
                  },
                  {
                    path: "4",
                    name: "route.contacts.dossierDetails.4",
                    component: () =>
                      import(
                        /* webpackChunkName: "contact-details" */ "@/vue/views/common/dossiers/phases/Phase4View.vue"
                      ),
                  },
                  {
                    path: "5",
                    name: "route.contacts.dossierDetails.5",
                    component: () =>
                      import(
                        /* webpackChunkName: "contact-details" */ "@/vue/views/common/dossiers/phases/Phase5View.vue"
                      ),
                  },
                  {
                    path: "6",
                    name: "route.contacts.dossierDetails.6",
                    component: () =>
                      import(
                        /* webpackChunkName: "contact-details" */ "@/vue/views/common/dossiers/phases/Phase6View.vue"
                      ),
                  },
                  {
                    path: "7",
                    name: "route.contacts.dossierDetails.7",
                    component: () =>
                      import(
                        /* webpackChunkName: "contact-details" */ "@/vue/views/common/dossiers/phases/Phase7View.vue"
                      ),
                    redirect: { name: "route.contacts.dossierDetails.7-1" },
                    children: [
                      {
                        path: "7-1",
                        name: "route.contacts.dossierDetails.7-1",
                        component: () =>
                          import(
                            /* webpackChunkName: "contact-details" */ "@/vue/views/common/dossiers/phases/Phase7x1View.vue"
                          ),
                      },
                      {
                        path: "7-2",
                        name: "route.contacts.dossierDetails.7-2",
                        component: () =>
                          import(
                            /* webpackChunkName: "contact-details" */ "@/vue/views/common/dossiers/phases/Phase7x2View.vue"
                          ),
                      },
                      {
                        path: "7-3",
                        name: "route.contacts.dossierDetails.7-3",
                        component: () =>
                          import(
                            /* webpackChunkName: "contact-details" */ "@/vue/views/common/dossiers/phases/Phase7x3View.vue"
                          ),
                      },
                    ],
                  },
                  {
                    path: "8",
                    name: "route.contacts.dossierDetails.8",
                    component: () =>
                      import(
                        /* webpackChunkName: "contact-details" */ "@/vue/views/common/dossiers/phases/Phase8View.vue"
                      ),
                  },
                ],
              },
            ],
          },
        ]
      : []),
    {
      path: "/notifications",
      name: "route.notifications",
      component: () => {
        return import(
          /* webpackChunkName: "notifications" */ "@/vue/views/common/NotificationsView.vue"
        );
      },
      meta: { requiresAuth: true },
    },
    {
      path: "/mon-compte",
      name: "route.myAccount",
      component: () => {
        return import(/* webpackChunkName: "my-account" */ "@/vue/views/common/MyAccountView.vue");
      },
      meta: { requiresAuth: true },
    },

    {
      path: "/500",
      name: "route.serverDown",
      component: () => {
        return import(
          /* webpackChunkName: "my-account" */ "@/vue/views/common/error/ServerDown.vue"
        );
      },
    },
    {
      path: "/404",
      name: "route.notFound",
      component: () => {
        return import(/* webpackChunkName: "my-account" */ "@/vue/views/common/error/NotFound.vue");
      },
    },

    {
      path: "/:catchAll(.*)",
      redirect: { name: "route.notFound" },
    },
  ];
};

export {
  requiresAuthButNotAuth,
  requiresUnauthButAuth,
  toDefaultRequiresUnauth,
  toDefaultRequiresAuth,
  toLastRoute,
  accessDenied,
  restrictDossierPhaseAccess,
  restrictPartnerDossierPricingPhaseAccess,
  commonRoutes,
  restrictClientPhaseAccess,
};
