import { phasesNames, phaseValidationStatus } from "@/constants/dossier.structure";

function dossierPricingPhasesAreValid(dossierPhasesStatuses) {
  const pricingPhases = [phasesNames.PHASE_A.key, phasesNames.PHASE_B.key];
  return pricingPhases.every(
    phase => dossierPhasesStatuses[phase].status === phaseValidationStatus.VALID.key
  );
}

export { dossierPricingPhasesAreValid };
